<template>
  <div class="vff outro">
    <iframe
      src="https://player.vimeo.com/video/554052359?background=1"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen
    ></iframe>

    <div class="answers-list vff-animate f-fade-in-up">
      <div
        class="answer"
        v-for="(item, x) in list"
        v-html="item"
        :key="x"
      ></div>
    </div>

    <button class="restart-btn" @click="started()">restart</button>

    <transition name="fade">
      <div class="fade-bg"></div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.onEnd();
  },
  methods: {
    onEnd: function () {
      const vm = this;
      fetch("https://staging.mindshaped.studio/api/list")
        .then((response) => response.json())
        .then((data) => {
          let random = data.data
            .sort(() => 0.5 - Math.random())
            .filter(function (item) {
              return item.closing_2;
            });
          random.forEach(function (user) {
            vm.list.push("<q>" + user.closing_2 + "</q> – " + user.title);
          });
        });
    },
    started() {
      this.$emit("start");
    },
  },
};
</script>
<style scoped>
iframe {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  object-fit: cover;
}
.outro {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
}
@media (max-width: 767px) {
  .intro {
    min-height: calc(100vh - 130px);
  }
  iframe {
    object-position: left;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.restart-btn {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
.fade-bg {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  height: 15vh;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.answers-list {
  max-width: 70%;
  top: 100vh;
  position: relative;
  box-sizing: border-box;
  animation: marquee 50s linear infinite;
  box-sizing: border-box;
}
.answer {
  margin-bottom: 2rem;
  font-size: 1.6rem;
}
.answers-list:hover {
  animation-play-state: paused;
}
@keyframes marquee {
  0% {
    top: 100vh;
  }
  100% {
    top: -100vh;
  }
}
</style>
