<template>
  <div class="vff intro" @click="onEnd()">
    <video
      autoplay
      muted
      playsinline
      preload="metadata"
      :class="ended ? 'ended' : ''"
    >
      <source src="../assets/Sphere_12.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>

    <transition name="fade">
      <div v-show="text" class="center">
        Welcome to an experiment in awareness
        <br />
        <span class="grey"
          >It's the invisible, the unspeakable, what keeps us blind and
          deaf</span
        ><br />
        <span class="small grey"
          >By filling out this survey, you agree on giving us permission to
          publish your comments, in total anonymity.</span
        >
      </div>
    </transition>

    <transition name="fade">
      <div style="transition-delay: 1.5s" v-show="text">
        <button
          class="o-btn-action"
          style="margin-top: 3rem !important"
          @click="started()"
          @enter="started()"
        >
          start
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ended: false,
      text: false,
    };
  },
  mounted() {
    setTimeout(() => this.onEnd(), 100);
    setTimeout(() => this.onText(), 5000);
  },
  methods: {
    onEnd: function () {
      this.ended = true;
    },
    onText: function () {
      this.text = true;
    },
    started() {
      this.$emit("start");
    },
  },
};
</script>
<style scoped>
video {
  width: auto;
  height: 50vmin;
  pointer-events: none;
  position: fixed;
  left: 50%;
  top: 10vh;
  transform: translateX(-50%);
}
.intro {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10vh;
  align-items: center;
  min-height: 100vh;
  margin: 0 !important;
}
@media (max-width: 767px) {
  .intro {
    min-height: calc(100vh - 65px);
  }
}
.intro > * {
  z-index: 2;
}
.intro > video {
  z-index: 1;
}
@media (max-width: 1024px) {
  video {
    width: 80%;
    height: auto;
    top: 2vh;
  }
  .intro {
    font-size: 1.2rem;
    padding: 0 2rem 2rem 2rem;
  }
}
</style>
