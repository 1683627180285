<template>
  <div class="about-overlay vff">
    <div class="close-about" @click="$emit('close')">&times;</div>
    <h4 style="margin-bottom: 0">
      <span style="white-space: nowrap">Manuelita Antonio</span>,
      <span style="white-space: nowrap">Nico Brühlmann</span>,
      <span style="white-space: nowrap">Larissa Igihozo Murangira</span>
    </h4>
    <h5 style="margin-top: 0.5em">Title of Work: Spheres of Privilege</h5>
    <p>
      Spheres of Privilege is an online interactive tool which aims to create a consensual safe space for reflections
      and an exchange around privilege. Being constantly exposed from behind our screens to violent racist, homophobic,
      sexist and other unequal situations, to start conversations around privilege has been an urgent topic during the
      pandemic. Synthesizing a survey of fundamental questions about race, class, and gender during specific chapters of
      life – the aim is to invite people to raise their voices and share about their underprivileged moments, or be
      confronted and aware of their privileges. These answers are then collected into a library of experiences,
      accessible to everyone to freely educate themselves on the different gaps of access to resources like education
      and services, the inequalities and violences that others can endure, with the wish to encourage people to make the
      first steps and adjustments in their daily lives to shorten these gaps and extend the conversation about
      privilege.<br /><br />
    </p>

    <p>
      First published May 26-27, 2020 at:
      <a
        style="text-decoration: underline; text-underline-offset: 10%"
        href="https://swissnex.org/sanfrancisco/event/beyond-2020-a-post-pandemic-festival/"
        target="_blank"
        >Beyond 2020: A (Post-) Pandemic Festival </a
      ><br /><br />
    </p>
    <vue-plyr>
      <div class="plyr__video-embed">
        <iframe
          src="https://www.youtube.com/embed/ZQ9wxIMEAFs?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1"
          allowfullscreen
          allowtransparency
          allow="autoplay"
        ></iframe>
      </div>
    </vue-plyr>

    <p>
      <b style="display: inline-block">Support Team:</b> <br />Pablo Peña: 3D Animator<br />
      Iván Mario Maldonado: Video Editor<br />
      Ariane Gelardine: Copy Editor <br />Matthias Zaugg: Sociologist
    </p>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
.about-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: white;
  overflow: auto;
  padding: 2rem !important;
  margin-top: 0;
  background: black;
  z-index: 999991;
}
.about-overlay p {
  font-size: 0.8em;
  max-width: 1200px;
}
.about-overlay b {
  margin-bottom: 0.5em;
  margin-top: 1em;
}
.close-about {
  position: fixed;
  top: 1rem;
  right: 3rem;
  cursor: pointer;
  font-size: 3.5rem;
  transition: opacity 0.3s ease;
  z-index: 999992;
  text-shadow: black 1px 1px 1px;
}
.close-about:hover {
  opacity: 0.6;
  transition: opacity 0.3s ease;
}
@media (max-width: 767px) {
  .close-about {
    top: 0;
    right: 1.5rem;
    font-size: 3rem;
  }
}
</style>
