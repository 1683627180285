var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"qanimate",staticClass:"vff-animate q-form center",class:_vm.mainClasses},[_c('div',{staticClass:"q-inner"},[_c('div',{class:{
        'f-section-wrap': _vm.question.type === _vm.QuestionType.SectionBreak
      }},[_c('div',[(_vm.question.tagline)?_c('span',{staticClass:"f-tagline"},[_vm._v(_vm._s(_vm.question.tagline))]):_vm._e(),(_vm.question.title)?[(_vm.question.type === _vm.QuestionType.SectionBreak)?_c('span',{staticClass:"fh2"},[_vm._v(_vm._s(_vm.question.title))]):_c('span',{staticClass:"f-text"},[_vm._v(" "+_vm._s(_vm.question.title)+" "),(_vm.question.required)?_c('span',{staticClass:"f-required",attrs:{"aria-label":_vm.language.ariaRequired,"role":"note"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("*")])]):_vm._e(),(_vm.question.inline)?_c('span',{staticClass:"f-answer"},[_c(_vm.question.type,{ref:"questionComponent",tag:"component",attrs:{"question":_vm.question,"language":_vm.language,"active":_vm.active},on:{"next":_vm.onEnter},model:{value:(_vm.dataValue),callback:function ($$v) {_vm.dataValue=$$v},expression:"dataValue"}})],1):_vm._e()])]:_vm._e(),(_vm.showHelperText)?_c('span',{staticClass:"f-sub"},[(_vm.question.subtitle)?_c('span',[_vm._v(_vm._s(_vm.question.subtitle))]):_vm._e(),(_vm.question.type === _vm.QuestionType.LongText && !_vm.isMobile)?_c('span',{staticClass:"f-help",domProps:{"innerHTML":_vm._s(
              _vm.question.helpText ||
                _vm.language.formatString(_vm.language.longTextHelpText)
            )}}):_vm._e(),(
              _vm.question.type === _vm.QuestionType.MultipleChoice &&
                _vm.question.multiple
            )?_c('span',{staticClass:"f-help"},[_vm._v(_vm._s(_vm.question.helpText || _vm.language.multipleChoiceHelpText))]):(_vm.question.type === _vm.QuestionType.MultipleChoice)?_c('span',{staticClass:"f-help"},[_vm._v(_vm._s(_vm.question.helpText || _vm.language.multipleChoiceHelpTextSingle))]):_vm._e()]):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(!_vm.question.inline)?_c('div',{staticClass:"f-answer"},[_c(_vm.question.type,{ref:"questionComponent",tag:"component",attrs:{"question":_vm.question,"language":_vm.language,"active":_vm.active},on:{"next":_vm.onEnter},model:{value:(_vm.dataValue),callback:function ($$v) {_vm.dataValue=$$v},expression:"dataValue"}})],1):_vm._e()])],2),_c('transition',{attrs:{"name":"fade"}},[(_vm.question.description || _vm.question.descriptionLink.length !== 0)?_c('p',{staticClass:"f-description grey",staticStyle:{"transition-delay":"500ms"}},[(_vm.question.description)?_c('span',[_vm._v(_vm._s(_vm.question.description))]):_vm._e(),_vm._l((_vm.question.descriptionLink),function(link,index){return _c('a',{key:'m' + index,staticClass:"f-link",attrs:{"href":link.url,"target":link.target}},[_vm._v(_vm._s(link.text || link.url))])})],2):_vm._e()])],1),(_vm.showOkButton())?_c('div',{staticClass:"vff-animate f-fade-in f-enter"},[_c('button',{ref:"button",staticClass:"o-btn-action",attrs:{"type":"button","href":"#","aria-label":_vm.language.ariaOk},on:{"click":function($event){$event.preventDefault();return _vm.onEnter($event)}}},[(_vm.question.type === _vm.QuestionType.SectionBreak)?_c('span',[_vm._v(_vm._s(_vm.language.continue))]):(_vm.showSkip())?_c('span',[_vm._v(_vm._s(_vm.language.skip))]):_c('span',[_vm._v(_vm._s(_vm.language.ok))])])]):_vm._e(),(_vm.showInvalid())?_c('div',{staticClass:"f-invalid",attrs:{"role":"alert","aria-live":"assertive"}},[_vm._v(" "+_vm._s(_vm.language.invalidPrompt)+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }